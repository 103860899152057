import React, { useEffect, useState } from 'react'
import {
  fieldsForInput,
  fieldsForSmartSelect,
  fieldsForToggle,
  fieldsForDomain,
  fieldsForSmartCreatableSelect,
} from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/fields/utils'
import { useFormikContext } from 'formik'
import { IntegrationFormValues } from 'Nbee'
import { CredentialFieldInput } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/fields/CredentialFieldInput'
import { CredentialFieldSmartSelect } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/fields/CredentialFieldSmartSelect'
import { CredentialFieldToggle } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/fields/CredentialFieldToggle'
import { FormInputGroup } from '@features/nbee/IntegrationCredentialsContent/styled'
import { Label } from '@components/Form/Label'
import { IntegrationNameInput } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/fields/IntegrationNameInput'
import { useTranslation } from 'react-i18next'
import { LabelWithDocTooltip } from '@components/Form/LabelWithDocTooltip'
import { CredentialFieldDomain } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/fields/CredentialFieldDomain'
import { CredentialFieldSmartCreatableSelect } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/fields/CredentialFieldSmartCreatableSelect'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { parseApiError } from '@app/api/utils/error'
import { useRefetchCredentialSchema } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/useRefetchCredentialSchema'
import { CredentialFieldVerifyEmail } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/fields/CredentialFieldVerifyEmail'
import { generateRandomId } from '@app/utils/randomIdGenerator'
import { useGetVerifiedEmails } from '@app/api/getVerifiedEmails'
import { ApiUserVerifiedEmailsObj } from '@app/@typings/Api/user'

interface Props {
  showName: boolean
  appName: string
  appId: number
  handleErrorsUpdate: (status: boolean) => void
}

export const FormInner: React.VFC<Props> = ({
  showName,
  appName,
  appId,
  handleErrorsUpdate,
}) => {
  const { t } = useTranslation()
  const { values, setValues } = useFormikContext<IntegrationFormValues>()
  const fields = [...values.credentials] || []
  const [emailsUpdated, setEmailsUpdated] = useState<
    ApiUserVerifiedEmailsObj[] | undefined
  >([])

  const isEmailValidation = fields.some(
    (field) =>
      field.type === 'validate_email' || field.type === 'validate_email_multi'
  )

  // Fetch Verified Emails
  const {
    data: emailsData,
    isLoading: isEmailsLoading,
    refetch: refetchEmails,
  } = useGetVerifiedEmails(isEmailValidation)

  // Add new value to emailsDataUpdated
  const handleAddNewValue = (newValue: ApiUserVerifiedEmailsObj) => {
    if (emailsUpdated) setEmailsUpdated([...emailsUpdated, newValue])
  }

  // Handle email refetch
  const handleEmailsRefetch = () => {
    refetchEmails().then((res) => {
      setEmailsUpdated(res?.data?.contacts)
    })
  }

  /**
   * Verify all email fields and update the form error state.
   *
   * This function iterates over the email fields in the `fields` array and checks that they have all been verified.
   * If any of the email fields are invalid, the `handleErrorsUpdate` function is called with a `true` parameter to
   * update the form error state.
   */
  const verifyAllEmails = () => {
    const allVerified = [...values.credentials]
      .filter(
        (field) =>
          field.type === 'validate_email' ||
          field.type === 'validate_email_multi'
      )
      .every((field) => {
        if (
          Array.isArray(field.value) &&
          field.type === 'validate_email_multi'
        ) {
          if (!field.isRequired) {
            if (field.value.length === 1 && field.value[0] === '') {
              return true
            }
            if (
              field.value.length > 1 &&
              field.value[field.value.length - 1] === ''
            ) {
              return false
            }
            if (
              field.value.length > 0 &&
              field.value[field.value.length - 1] !== ''
            ) {
              const areAllEmailsVerified = field.value.every((email) => {
                const emailData = emailsUpdated?.find(
                  (data) => data.email === email
                )
                if (emailData && emailData.status === 'verified') return true
                else return false
              })

              return areAllEmailsVerified
            }

            return field.value.every((email) => {
              return typeof email === 'string' // If we don't do this check, pressing Enter submits the form!
            })
          } else if (field.isRequired) {
            if (
              field.value.length > 1 &&
              field.value[field.value.length - 1] === ''
            ) {
              return false
            }
            return field.value.every((email) => {
              const emailData = emailsUpdated?.find(
                (data) => data.email === email
              )
              return emailData && emailData.status === 'verified'
            })
          } else {
            const emailData = emailsUpdated?.find(
              (data) => data.email === field.value
            )
            return emailData && emailData.status === 'verified'
          }
        } else {
          const emailData = emailsUpdated?.find(
            (data) => data.email === field.value
          )
          return emailData && emailData.status === 'verified'
        }
      })
    handleErrorsUpdate(!allVerified)
  }

  useEffect(() => {
    if (!isEmailsLoading && emailsData) {
      setEmailsUpdated(emailsData.contacts)
    }
  }, [isEmailsLoading, emailsData])

  useEffect(() => {
    verifyAllEmails()
  }, [values, emailsUpdated, isEmailsLoading])

  // Assign an empty string to the empty array we get from the api for cc and bcc
  // in order to be able to map the fields
  useEffect(() => {
    for (const field of [...values.credentials]) {
      if (field.type === 'validate_email_multi') {
        if (Array.isArray(field.value) && field.value.length === 0) {
          // if we find that in the array of fields there's an empty array,
          // we want to convert those empty arrays in an array with an empty string.
          // in order to do so, we need to create a shallow copy of fields
          // and pass the new value when the type is validate_email_multi
          // and the field is not required (e.g. 'cc' and 'bcc')
          const newFields = fields.map((mappedField) => {
            if (
              Array.isArray(mappedField.value) &&
              mappedField.value.length === 0
            ) {
              return { ...mappedField, value: [''] }
            } else {
              return mappedField
            }
          })
          // lastly, we use the Formik setValues method to update the Formik state
          setValues({ ...values, credentials: newFields })
        }
      }
    }
  }, [values])

  // This function handles when the Add recipient button should be shown or not
  const handleAddRecipient = (typeId: string) => {
    const toObject = [...values.credentials].find(({ id }) => id === typeId)
    if (!toObject) {
      return
    }
    const toObjectIndex = [...values.credentials].findIndex(
      ({ id }) => id === typeId
    )
    const newFields = [...values.credentials]
    newFields[toObjectIndex] = {
      ...toObject,
      value: Array.isArray(toObject.value) ? [...toObject.value, ''] : [''],
    }

    setValues({ ...values, credentials: newFields })
  }

  // every time a field with hasChild as true is updated we need to check if
  // new fields are available by calling our api endpoint
  const { errorApiAppAuthSchema, isLoadingAppAuthSchema } =
    useRefetchCredentialSchema(appId)

  return showName ? (
    <FormInputGroup centered>
      <Label $label={t('nbee.bridgeBuilder.integrationName')} />
      <IntegrationNameInput appName={appName} />
    </FormInputGroup>
  ) : (
    <>
      {fields.map((field, index) =>
        field.hide ? null : (
          <FormInputGroup key={field.id}>
            {field.type !== 'toggle' ? (
              <LabelWithDocTooltip
                label={field.label}
                htmlFor={field.id}
                isRequired={field.isRequired}
                tooltip={field.tooltip}
                docUrl={field.docUrl}
              />
            ) : null}
            {fieldsForInput.includes(field.type) ? (
              <CredentialFieldInput
                index={index}
                isLoading={isLoadingAppAuthSchema}
              />
            ) : fieldsForSmartSelect.includes(field.type) ? (
              <CredentialFieldSmartSelect
                index={index}
                isLoading={isLoadingAppAuthSchema}
                apiHasError={!!errorApiAppAuthSchema}
              />
            ) : field.type === 'validate_email' ? (
              <>
                <CredentialFieldVerifyEmail
                  index={index}
                  isLoading={isLoadingAppAuthSchema}
                  apiHasError={!!errorApiAppAuthSchema}
                  handleAddRecipient={handleAddRecipient}
                  emailsUpdated={emailsUpdated}
                  handleNewValue={handleAddNewValue}
                  handleEmailsRefetch={handleEmailsRefetch}
                />
              </>
            ) : field.type === 'validate_email_multi' &&
              Array.isArray(field.value) ? (
              <>
                {field.value.length > 0
                  ? field.value.map((value, idx) => (
                      <div
                        style={{ marginBottom: '.5rem' }}
                        key={generateRandomId()}
                      >
                        <CredentialFieldVerifyEmail
                          index={index}
                          isLoading={isLoadingAppAuthSchema}
                          apiHasError={!!errorApiAppAuthSchema}
                          handleAddRecipient={handleAddRecipient}
                          isLast={
                            Array.isArray(field.value) &&
                            idx === field.value.length - 1
                          }
                          idx={idx}
                          emailsUpdated={emailsUpdated}
                          handleNewValue={handleAddNewValue}
                          handleEmailsRefetch={handleEmailsRefetch}
                        />
                      </div>
                    ))
                  : null}
              </>
            ) : fieldsForSmartCreatableSelect.includes(field.type) ? (
              <CredentialFieldSmartCreatableSelect
                index={index}
                isLoading={isLoadingAppAuthSchema}
              />
            ) : fieldsForToggle.includes(field.type) ? (
              <CredentialFieldToggle
                index={index}
                isLoading={isLoadingAppAuthSchema}
              />
            ) : fieldsForDomain.includes(field.type) ? (
              <CredentialFieldDomain
                index={index}
                isLoading={isLoadingAppAuthSchema}
              />
            ) : null}
          </FormInputGroup>
        )
      )}

      {errorApiAppAuthSchema && (
        <InputFeedback
          $status={{
            error: parseApiError(errorApiAppAuthSchema).message,
          }}
        />
      )}
    </>
  )
}
