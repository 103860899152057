import React from 'react'
import { FormikInputField } from '@components/Formik/FormikInputField'
import { Button } from '@components/Basic/ButtonNbe'
import { useFastAppSurveyIndustries } from '@app/api/fastAppSurveyIndustries'
import { FormikInputSelect } from '@components/Formik/FormikInputSelect'
import styled from 'styled-components'
import { FormikInputCheckbox } from '@components/Formik/FormikInputCheckbox'
import { Trans, useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { SignupSurveyFormValues } from 'SignupSurvey'
import { Link, useLocation } from 'react-router-dom'
import { makeLoginUrl } from '@features/SignupSurvey/formUtils'

const Wrapper = styled.div`
  max-width: 450px;
  margin: 0 auto 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const TermsText = styled.div`
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`

export const FormInner: React.VFC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const getIndustries = useFastAppSurveyIndustries()
  const { isSubmitting } = useFormikContext<SignupSurveyFormValues>()

  return (
    <Wrapper>
      <FormikInputField
        name='email'
        type='email'
        label={t('auth.signupSurvey.labelEmail')}
        $fluid
        $hasMargin
      />

      <FormikInputField name='phone' type='tel' label='Phone' $fluid />

      <FormikInputSelect
        $options={getIndustries.data || []}
        $loading={getIndustries.isLoading}
        disabled={Boolean(getIndustries.error)}
        $optionsLabel={
          getIndustries.error
            ? 'Unable to retrieve industries'
            : t('auth.signupSurvey.industryLabel')
        }
        name={'industry'}
        $label={'Industry'}
        $hasMargin
      />

      <div>
        <FormikInputCheckbox name='privacyConsent'>
          <TermsText>
            <Trans
              ns={'all'}
              i18nKey={'auth.signup.termsAndCondition'}
              components={{
                a: <a />,
              }}
            />
          </TermsText>
        </FormikInputCheckbox>
      </div>

      <Button
        type={'submit'}
        $variant={'primary'}
        $loading={isSubmitting}
        disabled={isSubmitting}
      >
        {t('auth.signupSurvey.submitCta')}
      </Button>

      <div
        style={{
          textAlign: 'center',
        }}
      >
        {t('auth.signin.alreadyHaveAnAccount')}{' '}
        <Link to={makeLoginUrl(location.pathname)}>
          {t('auth.signin.ctaLoginViaEmail')}
        </Link>
      </div>
    </Wrapper>
  )
}
