import React from 'react'
import {
  SimpleBridgeBuilderWrapper,
  SimpleBridgeBuilderCard,
  SimpleBridgeBuilderCardSeparator,
} from '../SimpleBridgeBuilderForm/styled'
import { AppSelector } from '@features/nbee/SimpleBridgeBuilderForm/fields/AppSelector'
import { useTranslation } from 'react-i18next'
import { AiOutlinePlus } from 'react-icons/ai'
import { useHandleAppsCompatibility } from '@features/nbee/SimpleBridgeBuilderForm/appsCompatibility/useHandleAppsCompatibility'

export const FormInner: React.FC = () => {
  const { t } = useTranslation()
  const { appsAreCompatible } = useHandleAppsCompatibility()

  return (
    <SimpleBridgeBuilderWrapper>
      {/* SOURCE */}
      <SimpleBridgeBuilderCard>
        <h1>{t('nbee.bridgeBuilder.sourceTitle')}</h1>
        <h4>{t('nbee.bridgeBuilder.sourceSubTitle')}</h4>
        <AppSelector type='source' />
      </SimpleBridgeBuilderCard>

      {/* SEPARATOR */}
      <SimpleBridgeBuilderCardSeparator>
        <AiOutlinePlus size={'1.5rem'} />
      </SimpleBridgeBuilderCardSeparator>

      {/* DESTINATION */}
      <SimpleBridgeBuilderCard>
        <h1>{t('nbee.bridgeBuilder.destinationTitle')}</h1>
        <h4>{t('nbee.bridgeBuilder.destinationSubTitle')}</h4>
        <AppSelector type='destination' />
      </SimpleBridgeBuilderCard>
    </SimpleBridgeBuilderWrapper>
  )
}
