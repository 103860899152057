import React, { useEffect } from 'react'
import { FieldToggleStatus } from '@features/nbee/EmailReceipt/FieldToggleStatus'
import { FieldRecipients } from '@features/nbee/EmailReceipt/FieldRecipients'
import { FieldSubject } from '@features/nbee/EmailReceipt/FieldSubject'
import { Form, useFormikContext } from 'formik'
import { EmailReceiptStyled } from '@features/nbee/EmailReceipt/styled'
import { EmailReceiptSettings, BridgeFormValues } from 'Nbee'
import { useEmailReceiptValidation } from '@features/nbee/EmailReceipt/useEmailReceiptValidation'

const { SettingsFieldsContainer, Wrapper } = EmailReceiptStyled

interface Props {
  handleSubmit: (values: EmailReceiptSettings) => void
  isBridgeEnabled?: boolean
  initialValues: EmailReceiptSettings
}

export const FormInner: React.VFC<Props> = ({
  handleSubmit,
  isBridgeEnabled,
  initialValues,
}) => {
  const { values, setValues } = useFormikContext<BridgeFormValues>()
  const validationErrors = useEmailReceiptValidation(values)

  return (
    <Wrapper>
      <FieldToggleStatus
        forcedDisabled={isBridgeEnabled}
        onStatusChange={(isActive) => {
          const newEmailReceiptSettings = {
            ...initialValues,
            active: isActive,
          }

          const updatedValues = {
            ...values,
            settings: {
              ...values.settings,
              emailReceipt: newEmailReceiptSettings,
            },
          }

          setValues(updatedValues, false)
          handleSubmit(newEmailReceiptSettings)
        }}
      />

      {initialValues.active ? (
        <SettingsFieldsContainer>
          <FieldSubject
            disabled={isBridgeEnabled}
            error={validationErrors.subject}
          />
          <FieldRecipients
            disabled={isBridgeEnabled}
            error={validationErrors.recipients}
          />
        </SettingsFieldsContainer>
      ) : null}
    </Wrapper>
  )
}
